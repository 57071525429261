// components/VideoLibrary/ui/PlayerPositionSlider.tsx
import mq from "@/utils/mq";
import { TimeSlider, Time } from "@vidstack/react";
import styled, { css } from "styled-components";

const PositionSliderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-grow: 1;
  cursor: initial;
`;

const SliderRoot = styled(TimeSlider.Root)`
  width: 100%;
  pointer-events: auto;
  z-index: 2147483647115;
  cursor: pointer;
`;

const Track = styled(TimeSlider.Track)`
  border-radius: 90px;
  background: rgba(255, 255, 255, 0.16);
`;

const TrackFill = styled(TimeSlider.TrackFill)`
  background: #d9d9d9;
  border-top-left-radius: 90px;
  border-bottom-left-radius: 90px;
`;

const TimeLabel = styled(Time)`
  color: #f7f8f8;
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
  cursor: initial;
`;

/**
 * Position slider component for video player
 * Shows current time, total duration, and a slider to control playback position
 */
export default function PlayerPositionSlider() {
  return (
    <PositionSliderContainer>
      <TimeLabel className='vds-time' type='current' />
      <SliderRoot className='vds-slider'>
        <Track className='vds-slider-track' />
        <TrackFill className='vds-slider-track-fill vds-slider-track' />
        <TimeSlider.Thumb className='vds-slider-thumb' />
      </SliderRoot>
      <TimeLabel className='vds-time' type='duration' />
    </PositionSliderContainer>
  );
}
