import React, { useState, useEffect, MouseEvent, useRef, useCallback, useMemo } from "react";
import { MediaPlayer, MediaProvider, Poster, Spinner } from "@vidstack/react";
import { createPortal } from "react-dom";
import useOnclickOutside from "react-cool-onclickoutside";
import { isIOS } from "react-device-detect";
import { AnimatePresence } from "motion/react";

import VideoPlayerLayout, { StyledMediaPlayer, PlayerControlsRoot, SpinnerOuterContainer } from "./VideoPlayerLayout";
import VideoMetaHeader from "./VideoMetaHeader";
import VideoPlayerControls from "../ui/VideoPlayerControls";
import VideoPlayerErrorDisplay from "../ui/VideoPlayerErrorDisplay";
import MobilePlayerBottomControls from "../ui/MobilePlayerBottomControls";
import MobilePlayerOverlayControls from "../ui/MobilePlayerOverlayControls";
import useBreakpoint from "../hooks/useBreakpoint";
import { Video } from "../types";
import { getCorsSafeUrl, getYouTubeVideoId, formatIso8601Duration } from "../utils/videoHelpers";
import styled, { css } from "styled-components";
import mq from "@/utils/mq";
import VideoJsonLd from "@/components/Shared/JsonLD/VideoJsonLd";

const Desktop = styled.div`
  display: block;
  ${mq.mobile(css`
    display: none;
  `)};
`;

const Mobile = styled.div`
  display: none;
  ${mq.mobile(css`
    display: block;
  `)};
`;

// Animation variants for entry and exit
const animationVariants = {
  initial: {
    opacity: 0,
    scale: 0.95,
    filter: "blur(8px)",
  },
  animate: {
    opacity: 1,
    scale: 1,
    filter: "blur(0px)",
  },
  exit: {
    opacity: 0,
    scale: 0.9,
    filter: "blur(8px)",
    transition: {
      duration: 0.22,
      ease: [0.32, 0.72, 0, 1],
    },
  },
};

// Animation transition settings for enter
const animationTransition = {
  type: "spring",
  stiffness: 300,
  damping: 25,
  mass: 0.8,
};

export default function VideoPlayer({
  video,
  isOpen,
  setIsOpen,
}: {
  video: Video;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) {
  // Use our custom breakpoint hook instead of device detection
  const { isMobileView } = useBreakpoint();
  const [isPlaying, setIsPlaying] = useState(false);
  const [muted, setMuted] = useState(isIOS);
  const [error, setError] = useState<Error | null>(null);
  const [isMounted, setIsMounted] = useState(false);
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  // Add a specific state for tracking when media can actually play
  const [canPlay, setCanPlay] = useState(false);
  const [displayControls, setDisplayControls] = useState(true);
  // Add state to track when YouTube provider should be mounted to avoid race conditions
  const [shouldRenderProvider, setShouldRenderProvider] = useState(false);
  // Add state to track exit animation
  const [isExiting, setIsExiting] = useState(false);

  // Create ref for the media player
  const playerRef = useRef<any>(null);
  // Track unmounting with a ref to prevent state updates after unmounting
  const unmountingRef = useRef(false);
  // Track our timeout IDs
  const timeoutRef = useRef<number | null>(null);
  // Track controls auto-hide timeout
  const controlsTimeoutRef = useRef<number | null>(null);
  // AbortController for handling pending operations
  const abortControllerRef = useRef<AbortController | null>(null);
  // Track if player is initialized
  const isPlayerInitializedRef = useRef(false);

  // Process video for CORS-safe URLs
  const corsFixedVideo = useMemo(() => {
    if (!video) return video;

    return {
      ...video,
      // Don't modify videoLink as it's a YouTube URL
      // Fix Storyblok asset URLs
      thumbnail: video.thumbnail ? { ...video.thumbnail, filename: getCorsSafeUrl(video.thumbnail) } : video.thumbnail,
      poster: video.poster ? { ...video.poster, filename: getCorsSafeUrl(video.poster) } : video.poster,
      preview: video.preview ? { ...video.preview, filename: getCorsSafeUrl(video.preview) } : video.preview,
    };
  }, [video]);

  // Ensure we have a valid video source
  const videoSource = useMemo(() => {
    return corsFixedVideo?.videoLink || "";
  }, [corsFixedVideo]);

  // Function to check if player is available
  const isPlayerAvailable = useCallback(() => {
    return playerRef.current !== null && !unmountingRef.current && isPlayerInitializedRef.current && isMounted;
  }, [isMounted]);

  // Function to clear the controls timeout
  const clearControlsTimeout = useCallback(() => {
    try {
      if (controlsTimeoutRef.current !== null) {
        window.clearTimeout(controlsTimeoutRef.current);
        controlsTimeoutRef.current = null;
      }
    } catch (e) {
      console.debug("Error clearing controls timeout:", e);
    }
  }, []);

  // Function to reset the auto-hide timer - just clears existing timeout
  // The actual setup happens in the dedicated useEffect hook
  const resetControlsTimer = useCallback(() => {
    // We only clear the existing timeout here
    // This gets called whenever there's user interaction

    clearControlsTimeout();
  }, [clearControlsTimeout]);

  // Cleanup function to properly dispose the player
  const cleanupPlayer = useCallback(() => {
    // Clear any pending timeouts
    if (timeoutRef.current !== null) {
      window.clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    // Clear controls timeout
    clearControlsTimeout();

    // Cancel any pending operations with AbortController
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      // Create a fresh controller in case component is not unmounting
      if (!unmountingRef.current) {
        abortControllerRef.current = new AbortController();
      }
    }

    // Stop playback immediately
    if (!unmountingRef.current) {
      setIsPlaying(false);
    }

    // Reset player ready state
    if (!unmountingRef.current) {
      setIsPlayerReady(false);
      setCanPlay(false);
    }

    // If we have a reference to the player, pause it and prepare for disposal
    if (playerRef.current && isPlayerInitializedRef.current) {
      try {
        // Make sure the player is paused and unloaded properly
        // Safely check before calling methods
        if (typeof playerRef.current.pause === "function") {
          playerRef.current.pause();
        }

        // Reset player state completely
        try {
          // Different methods for properly disposing media in vidstack
          if (typeof playerRef.current.unload === "function") {
            playerRef.current.unload();
          }
        } catch (e) {
          // Ignore errors during cleanup
          console.debug("Error unloading player:", e);
        }
      } catch (e) {
        // Ignore errors during cleanup
        console.debug("Error while cleaning up player:", e);
      }
    }

    // Reset player initialized state
    isPlayerInitializedRef.current = false;
  }, [clearControlsTimeout]);

  // Handle animation complete event for exit animation
  const handleAnimationComplete = useCallback(
    (definition) => {
      if (definition === "exit" && !unmountingRef.current) {
        // Clean up player when exit animation completes
        cleanupPlayer();
        // Only set isOpen to false if we're exiting
        if (isExiting) {
          setIsOpen(false);
          setIsExiting(false);
        }
      }
    },
    [isExiting, setIsOpen, cleanupPlayer],
  );

  // Handle clicks outside the player - desktop behavior only
  const containerRef = useOnclickOutside(() => {
    // Only close the player on larger viewports
    if (!unmountingRef.current && !isMobileView) {
      // Start exit animation instead of immediately closing
      setIsExiting(true);
    }
  });

  // Handle player ready state to ensure autoplay works properly
  const handlePlayerReady = useCallback(() => {
    if (!unmountingRef.current) {
      setIsPlayerReady(true);
      isPlayerInitializedRef.current = true;
    }
  }, []);

  // Add handler for can-play event
  const handleCanPlay = useCallback(() => {
    if (!unmountingRef.current) {
      console.debug("Media can now play");
      setCanPlay(true);
    }
  }, []);

  // Handle video errors - updated to match MediaPlayer's expected type
  const handleError = useCallback((detail: any, nativeEvent: any) => {
    if (unmountingRef.current) return;

    console.error("Video playback error:", detail, nativeEvent);
    setError(new Error("Failed to play video. Please try again later."));
  }, []);

  // Handle YouTube links
  const handleYouTubeClick = useCallback(
    (e: React.MouseEvent) => {
      if (e && typeof e.stopPropagation === "function") {
        e.stopPropagation();
      }

      // Reset controls timeout on interaction
      resetControlsTimer();

      if (corsFixedVideo && corsFixedVideo.videoLink) {
        if (corsFixedVideo.videoLink.includes("youtube.com") || corsFixedVideo.videoLink.includes("youtu.be")) {
          window.open(corsFixedVideo.videoLink, "_blank");
        } else if (corsFixedVideo.title) {
          const searchQuery = encodeURIComponent(corsFixedVideo.title);
          window.open(`https://www.youtube.com/results?search_query=${searchQuery}`, "_blank");
        } else {
          window.open("https://www.youtube.com", "_blank");
        }
      }
    },
    [corsFixedVideo, resetControlsTimer],
  );

  // Handle close button click
  const handleCloseClick = useCallback(
    (e: React.MouseEvent) => {
      if (e && typeof e.stopPropagation === "function") {
        e.stopPropagation();
      }
      // Start exit animation instead of immediately closing
      setIsExiting(true);
    },
    [setIsExiting],
  );

  // Handle play button click
  const handlePlayClick = useCallback(
    (e?: React.MouseEvent) => {
      // If an event was passed, stop propagation
      if (e && typeof e.stopPropagation === "function") {
        e.stopPropagation();
      }

      // Reset controls timeout on interaction
      resetControlsTimer();

      if (!isPlayerAvailable()) return;

      if (!isPlaying) {
        try {
          if (typeof playerRef.current.play === "function") {
            playerRef.current.play().catch((e) => {
              console.debug("Could not play on button click:", e);
            });
          }
        } catch (e) {
          console.debug("Could not play on button click:", e);
        }
      } else {
        try {
          if (typeof playerRef.current.pause === "function") {
            playerRef.current.pause();
          }
        } catch (e) {
          console.debug("Could not pause on button click:", e);
        }
      }

      setIsPlaying(!isPlaying);

      // Important: On mobile, don't hide controls when explicitly clicking play/pause
      // This ensures the controls stay visible after clicking play/pause
      if (isMobileView) {
        setDisplayControls(true);
        // The auto-hide effect will handle setting up the timeout
      }
    },
    [isPlaying, isMobileView, resetControlsTimer, isPlayerAvailable],
  );

  // Handle background click - different behavior for mobile and desktop
  const handleBackgroundClick = useCallback(
    (e: MouseEvent<HTMLDivElement> | any) => {
      // Safely check if stopPropagation exists before calling it
      if (e && typeof e.stopPropagation === "function") {
        e.stopPropagation();
      }

      // For swipe gesture dismissal on mobile, we might not have a proper event
      // or the event target/currentTarget might be missing
      const shouldCloseFromSwipe = isMobileView && (!e || !e.target || !e.currentTarget);

      // Handle case for normal click
      const shouldCloseFromClick = e && e.target && e.currentTarget && e.target === e.currentTarget;

      // Close if either condition is met
      if (shouldCloseFromSwipe || shouldCloseFromClick) {
        // Start exit animation instead of immediately closing
        setIsExiting(true);
      }
    },
    [setIsExiting, isMobileView],
  );

  // Handle toggling controls visibility (for mobile)
  const handleToggleControls = useCallback(() => {
    if (isMobileView) {
      // Reset controls timeout on interaction
      resetControlsTimer();

      // Toggle controls visibility when clicking on the player area
      const newDisplayState = !displayControls;
      setDisplayControls(newDisplayState);

      // The auto-hide effect will handle setting up the timeout if needed
    }
  }, [displayControls, isMobileView, resetControlsTimer]);

  // Listen for "hidecontrols" event
  useEffect(() => {
    const hideControls = () => {
      if (isMobileView) {
        setDisplayControls(false);
        resetControlsTimer();
      }
    };

    document.addEventListener("hidecontrols", hideControls);

    return () => {
      document.removeEventListener("hidecontrols", hideControls);
    };
  }, [isMobileView, resetControlsTimer]);

  // Handle MediaProvider click
  const handleMediaProviderClick = useCallback(
    (e: React.MouseEvent) => {
      if (e && typeof e.stopPropagation === "function") {
        e.stopPropagation();
      }

      // Reset controls timeout on interaction
      resetControlsTimer();

      if (isMobileView) {
        // On mobile viewport, toggle controls visibility when clicking the video
        const newDisplayState = !displayControls;
        setDisplayControls(newDisplayState);

        // The auto-hide effect will handle setting up the timeout if needed
      } else {
        // On desktop viewport, toggle play/pause
        handlePlayClick();
      }
    },
    [displayControls, isMobileView, handlePlayClick, resetControlsTimer],
  );

  // Handle play and pause events
  const handlePlay = useCallback(() => {
    if (!unmountingRef.current) {
      setIsPlaying(true);
    }
  }, []);

  const handlePause = useCallback(() => {
    if (!unmountingRef.current) {
      setIsPlaying(false);
    }
  }, []);

  // Handle load start event
  const handleLoadStart = useCallback(() => {
    if (!unmountingRef.current) {
      handlePlayerReady();
    }
  }, [handlePlayerReady]);

  // Auto-hide controls effect - this is a dedicated effect just for the auto-hide functionality
  useEffect(() => {
    // Run this effect when controls are visible and video is playing (for both mobile and desktop)
    if (displayControls && isPlaying && !unmountingRef.current) {
      try {
        // Clear any existing timeout first
        clearControlsTimeout();

        // Create a new timeout that will hide the controls after 3 seconds
        controlsTimeoutRef.current = window.setTimeout(() => {
          // Only update state if component is still mounted
          if (!unmountingRef.current) {
            setDisplayControls(false);
          }
        }, 3000);
      } catch (e) {
        console.debug("Error setting up controls timeout:", e);
      }

      // Clean up function to clear the timeout when the component unmounts or dependencies change
      return () => {
        clearControlsTimeout();
      };
    }

    return () => {
      // Always ensure the timeout is cleared when this effect is cleaned up
      clearControlsTimeout();
    };
  }, [displayControls, isPlaying, clearControlsTimeout]);

  // Check if we're on the client side
  useEffect(() => {
    setIsMounted(true);
    unmountingRef.current = false;

    // Create a new AbortController for this component instance
    abortControllerRef.current = new AbortController();

    // Set up cleanup for navigation events
    const handleBeforeUnload = () => {
      unmountingRef.current = true;
      cleanupPlayer();
    };

    // Handle router navigation events for Next.js
    const handleRouteChange = () => {
      unmountingRef.current = true;
      cleanupPlayer();
    };

    // Listen for navigation and visibility change events
    window.addEventListener("beforeunload", handleBeforeUnload);
    document.addEventListener("visibilitychange", handleRouteChange);

    return () => {
      unmountingRef.current = true;
      window.removeEventListener("beforeunload", handleBeforeUnload);
      document.removeEventListener("visibilitychange", handleRouteChange);
      cleanupPlayer();
    };
  }, [cleanupPlayer]);

  // Handle autoplay when player is ready
  useEffect(() => {
    // Only attempt autoplay if player is ready, can play, is open, and not unmounting
    if (isPlayerReady && canPlay && isOpen && !unmountingRef.current && isPlayerAvailable()) {
      // For iOS devices, we need a small delay before playing
      if (isIOS) {
        // Clear any existing timeout first
        if (timeoutRef.current !== null) {
          window.clearTimeout(timeoutRef.current);
        }

        // Store the timeout ID so we can clear it during cleanup
        timeoutRef.current = window.setTimeout(() => {
          if (isMounted && isOpen && !unmountingRef.current && isPlayerAvailable()) {
            setIsPlaying(true);

            // Explicitly call play on the player element if available
            try {
              if (typeof playerRef.current.play === "function") {
                playerRef.current.play().catch((e) => {
                  console.debug("Could not autoplay on iOS:", e);
                });
              }
            } catch (e) {
              console.debug("Could not autoplay on iOS:", e);
            }
          }
        }, 500);

        return () => {
          if (timeoutRef.current !== null) {
            window.clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
          }
        };
      } else {
        // For non-iOS devices, play immediately
        setIsPlaying(true);

        // Explicitly call play on the player element if available
        try {
          if (typeof playerRef.current.play === "function") {
            playerRef.current.play().catch((e) => {
              console.debug("Could not autoplay:", e);
            });
          }
        } catch (e) {
          console.debug("Could not autoplay:", e);
        }
      }
    }
  }, [isPlayerReady, canPlay, isOpen, isMounted, isPlayerAvailable]);

  // Reset player when opened/closed
  useEffect(() => {
    if (!isMounted || unmountingRef.current) return;

    if (isOpen) {
      // When opening, we'll let the player ready handler trigger autoplay
      if (playerRef.current && !isPlayerReady) {
        try {
          // Refresh the player state when opening
          setIsPlaying(false);

          if (typeof playerRef.current.load === "function") {
            playerRef.current.load();
          }
        } catch (e) {
          console.debug("Could not reload player:", e);
        }
      }
      // Always show controls when opening
      setDisplayControls(true);
      // Reset exiting state when opening
      setIsExiting(false);

      // The dedicated auto-hide effect will handle setting the timeout
    } else {
      // When closing, stop everything
      cleanupPlayer();
    }
  }, [isOpen, isMounted, isPlayerReady, cleanupPlayer]);

  // Handle component unmounting - using a separate useEffect to track unmounting specifically
  useEffect(() => {
    return () => {
      unmountingRef.current = true;
      clearControlsTimeout();
      cleanupPlayer();
    };
  }, [cleanupPlayer, clearControlsTimeout]);

  // Handle player ref change
  const handlePlayerRef = useCallback((instance) => {
    playerRef.current = instance;

    if (instance) {
      // Set up any event listeners or initial configuration when ref is available
      isPlayerInitializedRef.current = true;
    } else {
      isPlayerInitializedRef.current = false;
    }
  }, []);

  // Check if the video is from YouTube
  const isYouTubeVideo = useMemo(() => {
    return videoSource && (videoSource.includes("youtube.com") || videoSource.includes("youtu.be"));
  }, [videoSource]);

  // Delay the rendering of the MediaProvider to avoid initialization errors
  useEffect(() => {
    if (isOpen && isMounted && !unmountingRef.current) {
      // Clear any existing timeouts
      if (timeoutRef.current !== null) {
        window.clearTimeout(timeoutRef.current);
      }

      // Set a small delay for mounting the provider
      timeoutRef.current = window.setTimeout(
        () => {
          if (!unmountingRef.current && isMounted) {
            setShouldRenderProvider(true);
          }
        },
        isYouTubeVideo ? 150 : 0,
      ); // Delay for YouTube videos, immediate for others

      return () => {
        if (timeoutRef.current !== null) {
          window.clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
        }
        setShouldRenderProvider(false);
      };
    } else if (!isOpen) {
      setShouldRenderProvider(false);
    }
  }, [isOpen, isMounted, isYouTubeVideo]);

  // Prepare JSON-LD data
  const jsonLdData = useMemo(() => {
    if (!video) return null;

    const videoId = getYouTubeVideoId(video.videoLink);
    const thumbnailUrl = video.thumbnail ? [getCorsSafeUrl(video.thumbnail.filename)] : [];
    const embedUrl = videoId ? `https://www.youtube.com/embed/${videoId}` : "";
    const duration = formatIso8601Duration(video.lengthMinutes, video.lengthSeconds);

    return {
      name: video.title,
      description: video.description,
      thumbnailUrl,
      uploadDate: video.publishDate,
      duration,
      contentUrl: video.videoLink,
      embedUrl,
    };
  }, [video]);

  // Create the player content based on error state
  const playerContent = useMemo(() => {
    if (error) {
      return <VideoPlayerErrorDisplay message={error.message} onClose={handleCloseClick} />;
    }

    return (
      <>
        {isOpen && jsonLdData && (
          <VideoJsonLd
            name={jsonLdData.name}
            description={jsonLdData.description}
            thumbnailUrl={jsonLdData.thumbnailUrl}
            uploadDate={jsonLdData.uploadDate}
            duration={jsonLdData.duration}
            contentUrl={jsonLdData.contentUrl}
            embedUrl={jsonLdData.embedUrl}
          />
        )}

        <StyledMediaPlayer
          as={MediaPlayer}
          ref={handlePlayerRef}
          title={corsFixedVideo?.title || ""}
          src={videoSource}
          playsInline
          muted={muted}
          id={corsFixedVideo?.videoLink || "video-player"}
          key={corsFixedVideo?.videoLink || "video-player"}
          autoPlay={isIOS ? false : true}
          onPlay={handlePlay}
          onPause={handlePause}
          paused={!isPlaying}
          load='eager'
          streamType='on-demand'
          onLoadStart={handleLoadStart}
          onCanPlay={handleCanPlay}
          onError={handleError}>
          {/* Only render the MediaProvider when it's safe to do so */}
          {shouldRenderProvider ? (
            <MediaProvider onClick={handleMediaProviderClick} />
          ) : (
            <div onClick={handleMediaProviderClick} style={{ width: "100%", height: "100%", position: "absolute" }} />
          )}

          {corsFixedVideo?.thumbnail && (
            <Poster
              className='vds-poster'
              src={corsFixedVideo.thumbnail.filename}
              alt={corsFixedVideo.thumbnail.alt || corsFixedVideo.title || "Video thumbnail"}
            />
          )}

          <SpinnerOuterContainer className='vds-buffering-indicator'>
            <Spinner.Root className='vds-buffering-spinner'>
              <Spinner.Track className='vds-buffering-track' />
              <Spinner.TrackFill className='vds-buffering-track-fill' />
            </Spinner.Root>
          </SpinnerOuterContainer>

          {/* Desktop controls */}
          <Desktop>
            <PlayerControlsRoot className='vds-controls' hideDelay={2000}>
              <VideoMetaHeader video={corsFixedVideo} onClose={handleCloseClick} onYouTubeClick={handleYouTubeClick} />
              <VideoPlayerControls isPlaying={isPlaying} onPlayClick={handlePlayClick} />
            </PlayerControlsRoot>
          </Desktop>

          {/* Mobile controls - all within MediaPlayer context */}
          <Mobile>
            {/* Mobile overlay controls (centered play button) - shown only when displayControls is true */}
            {displayControls && <MobilePlayerOverlayControls isPlaying={isPlaying} onPlayClick={handlePlayClick} />}

            {/* Mobile header styled to appear at top of screen - shown only when displayControls is true */}
            <PlayerControlsRoot
              className={`vds-controls mobile-header-controls ${displayControls ? "visible" : "hidden"}`}
              hideDelay={2000}>
              <VideoMetaHeader video={corsFixedVideo} onClose={handleCloseClick} onYouTubeClick={handleYouTubeClick} />
            </PlayerControlsRoot>

            {/* Mobile bottom controls - shown only when displayControls is true */}
            <PlayerControlsRoot
              className={`vds-controls mobile-bottom-controls ${displayControls ? "visible" : "hidden"}`}
              hideDelay={2000}>
              <MobilePlayerBottomControls isPlaying={isPlaying} onPlayClick={handlePlayClick} />
            </PlayerControlsRoot>
          </Mobile>
        </StyledMediaPlayer>
      </>
    );
  }, [
    corsFixedVideo,
    error,
    handleCloseClick,
    handleCanPlay,
    handleError,
    handleMediaProviderClick,
    handlePlay,
    handlePause,
    handlePlayClick,
    handleYouTubeClick,
    handleLoadStart,
    handlePlayerRef,
    isPlaying,
    isOpen,
    muted,
    videoSource,
    shouldRenderProvider,
    jsonLdData,
    isIOS,
  ]);

  // Only render the portal on the client side
  if (!isMounted) return null;

  // Use createPortal only when we're on the client side and the component is open
  return createPortal(
    <AnimatePresence mode='wait'>
      {isOpen && (
        <VideoPlayerLayout
          isOpen={isOpen}
          onBackgroundClick={handleBackgroundClick}
          containerRef={containerRef}
          onToggleControls={handleToggleControls}
          initial='initial'
          animation={isExiting ? "exit" : "animate"}
          exit='exit'
          variants={animationVariants}
          transition={animationTransition}
          onAnimationComplete={handleAnimationComplete}>
          {playerContent}
        </VideoPlayerLayout>
      )}
    </AnimatePresence>,
    document.body,
  );
}
