import React from "react";
import styled from "styled-components";

const ErrorContainer = styled.div`
  color: white;
  padding: 2rem;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ErrorHeading = styled.h3`
  font-size: 18px;
  margin-bottom: 1rem;
`;

const ErrorMessage = styled.p`
  font-size: 14px;
  opacity: 0.8;
  margin-bottom: 1.5rem;
`;

const CloseButton = styled.button`
  background: rgba(255, 255, 255, 0.2);
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  color: white;
  margin: 0 auto;
  cursor: pointer;
  font-size: 14px;
  transition: background 0.2s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }
`;

interface VideoPlayerErrorDisplayProps {
  message: string;
  onClose: (e: React.MouseEvent) => void;
}

/**
 * Error display component for video player errors
 */
export default function VideoPlayerErrorDisplay({ message, onClose }: VideoPlayerErrorDisplayProps) {
  return (
    <ErrorContainer onClick={(e) => e.stopPropagation()}>
      <ErrorHeading>Video Playback Error</ErrorHeading>
      <ErrorMessage>{message}</ErrorMessage>
      <CloseButton onClick={onClose}>Close</CloseButton>
    </ErrorContainer>
  );
}
