import React from "react";
import styled, { css } from "styled-components";
import { motion } from "motion/react";
import { PlayButton, useMediaState } from "@vidstack/react";

const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 2147483647150;
  pointer-events: none; /* Allow clicks to pass through to the video */
`;

const PlayButtonContainer = styled(PlayButton)`
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background: hsla(0, 0%, 100%, 0.16);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(8px);
  cursor: pointer;
  pointer-events: auto; /* Make the button clickable */
  transition: all 0.2s ease;

  &:hover {
    transform: scale(1.05);
    background: hsla(0, 0%, 100%, 0.16);
  }

  &:active {
    transform: scale(0.95);
  }
`;

// Simple play/pause icons
const PlayIcon = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 0 12px 20px;
  border-color: transparent transparent transparent white;
  margin-left: 4px;
`;

const PauseIcon = styled.div`
  width: 20px;
  height: 24px;
  display: flex;
  justify-content: space-between;

  &:before,
  &:after {
    content: "";
    display: block;
    width: 6px;
    height: 24px;
    background: white;
    border-radius: 2px;
  }
`;

interface MobilePlayerOverlayControlsProps {
  isPlaying: boolean;
  onPlayClick: () => void;
}

/**
 * Mobile-specific overlay controls for the video player
 * Contains centered play/pause button
 */
export default function MobilePlayerOverlayControls({ isPlaying, onPlayClick }: MobilePlayerOverlayControlsProps) {
  const isPaused = useMediaState("paused");

  return (
    <OverlayContainer>
      <PlayButtonContainer onClick={(e) => e.stopPropagation()} className='vds-button'>
        {!isPaused ? <PauseIcon /> : <PlayIcon />}
      </PlayButtonContainer>
    </OverlayContainer>
  );
}
