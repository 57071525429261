import React from "react";
import styled, { css } from "styled-components";
import { format } from "date-fns";
import mq from "@/utils/mq";
import { Video } from "../../types";

import CloseIcon from "@/assets/svg/closeIcon.svg";
import YouTubeIcon from "@/assets/svg/videoYouTube.svg";

const VideoMetaInfo = styled.div`
  z-index: 2147483647200;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 24px 36px;
  pointer-events: auto;
  //transform: translateY(-20px);
  transition:
    transform 0.3s ease-in-out,
    opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out;
  //opacity: 0;
  //visibility: hidden;

  ${mq.mobile(css`
    padding: 16px;
  `)};

  .vds-controls[data-visible] & {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
  ${mq.mobile(css`
    top: 50px;
    z-index: 200;
    padding: 0;
  `)};
`;

const VideoTitleLayer = styled.div`
  padding-top: 0px;
`;

const VideoTitle = styled.div`
  color: #f7f8f8;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const VideoMeta = styled.div`
  color: #f7f8f8;
  opacity: 0.8;
  margin: 0;
  font-size: 12px;
  line-height: 11px;
  width: 100%;
  margin-top: 4px;
`;

const VideoMetaControls = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: -5px;
`;

const CloseIconContainer = styled.button`
  cursor: pointer;
  display: flex;
  width: 32px;
  height: 32px;
  padding: 3px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #f7f8f8;
  background: none;
  border: none;
  outline: none;
  border-radius: 8px;
  transition:
    background 0.2s ease-in-out,
    transform 0.2s ease-in;
  backdrop-filter: blur(0px);
  transform: scale(1);

  &:hover {
    transition:
      background 0.2s ease-in-out,
      transform 0.2s ease-in;
    transform: scale(1.05);
    backdrop-filter: blur(8px);
    background: rgb(255 255 255 / 0.2);
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

const PlayerYouTubeButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: white;
  border: none;
  outline: none;
  border-radius: 8px;
  padding: 0 12px;
  height: 32px;
  font-size: 13px;
  font-weight: 500;
  gap: 8px;
  transition: background 0.2s ease-in-out transform 0.2s ease-in;
  backdrop-filter: blur(0px);
  transform: scale(1);

  &:hover {
    backdrop-filter: blur(8px);
    background: rgb(255 255 255 / 0.2);
    transition:
      background 0.2s ease-in-out,
      transform 0.2s ease-in;
  }

  ${mq.mobile(css`
    width: 32px;
    padding: 0;
  `)};

  svg {
    width: 16px;
    height: 15px;
    fill: currentColor;
  }
`;

const YoutubeButtonText = styled.span`
  ${mq.mobile(css`
    display: none;
  `)};
`;

interface VideoMetaHeaderProps {
  video: Video;
  onClose: (e: React.MouseEvent) => void;
  onYouTubeClick: (e: React.MouseEvent) => void;
}

/**
 * Video metadata header with title, date, and control buttons
 */
export default function VideoMetaHeader({ video, onClose, onYouTubeClick }: VideoMetaHeaderProps) {
  return (
    <VideoMetaInfo onClick={(e) => e.stopPropagation()}>
      <VideoTitleLayer>
        <VideoTitle>{video.title}</VideoTitle>
        <VideoMeta>
          {format(new Date(video.publishDate), "MMM d, yyyy")} · {video.lengthMinutes} min
        </VideoMeta>
      </VideoTitleLayer>

      <VideoMetaControls>
        <PlayerYouTubeButton onClick={onYouTubeClick}>
          <YouTubeIcon />
          <YoutubeButtonText>Watch on YouTube</YoutubeButtonText>
        </PlayerYouTubeButton>

        <CloseIconContainer onClick={onClose}>
          <CloseIcon />
        </CloseIconContainer>
      </VideoMetaControls>
    </VideoMetaInfo>
  );
}
