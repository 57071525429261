// components/VideoLibrary/ui/PlayerFullScreenToggle.tsx
import { FullscreenButton, useMediaState } from "@vidstack/react";
import styled from "styled-components";
import VideoExpandIcon from "@/assets/svg/videoExpand.svg";
import VideoCollapseIcon from "@/assets/svg/videoCompress.svg";
import { isIOS } from "react-device-detect";

const StyledFullscreenButton = styled(FullscreenButton)`
  cursor: pointer;
  width: 32px;
  height: 32px;
  color: #f7f8f8;
  &:where(.vds-button[aria-hidden="true"]) {
    display: flex !important;
  }
  svg {
    width: 16px;
    height: 16px;
  }
`;

export default function PlayerFullScreenToggle() {
  const isActive = useMediaState("fullscreen");

  if (isIOS) {
    return null;
  }

  return (
    <StyledFullscreenButton className='vds-button' aria-hidden='false'>
      {isActive ? <VideoCollapseIcon /> : <VideoExpandIcon />}
    </StyledFullscreenButton>
  );
}
