// components/VideoLibrary/ui/PlayerPlayButton.tsx
import { PlayButton, useMediaState } from "@vidstack/react";
import VideoPlayIcon from "@/assets/svg/videoPlay.svg";
import VideoPauseIcon from "@/assets/svg/videoPause.svg";
import styled from "styled-components";

const StyledPlayButton = styled(PlayButton)`
  cursor: pointer;
  width: 32px;
  height: 32px;
  color: #f7f8f8;
  svg {
    width: 16px;
    height: 16px;
  }
`;

/**
 * Play/pause button component for video player
 * Shows appropriate icon based on current play state
 */
export default function PlayerPlayButton({ onClick }: { onClick: () => void }) {
  const isPaused = useMediaState("paused");
  return (
    <StyledPlayButton onClick={onClick} className='vds-button'>
      {isPaused ? <VideoPlayIcon /> : <VideoPauseIcon />}
    </StyledPlayButton>
  );
}
