import { useState, useEffect } from "react";

// Define our breakpoint width to match the site's constants
// BREAKPOINT_SM from constants is 767
const MOBILE_BREAKPOINT = 767;

/**
 * Custom hook to detect viewport size using media queries
 * Returns boolean values for different breakpoints
 */
export default function useBreakpoint() {
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    // Function to check if viewport matches mobile breakpoint
    const checkMobile = () => {
      setIsMobileView(window.innerWidth <= MOBILE_BREAKPOINT);
    };

    // Check initially
    checkMobile();

    // Add event listener for resize
    window.addEventListener("resize", checkMobile);

    // Cleanup
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  return { isMobileView };
}
