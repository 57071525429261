import React from "react";
import styled, { css } from "styled-components";
import { motion } from "motion/react";
import mq from "@/utils/mq";
import PlayerPositionSlider from "../ui/PlayerPositionSlider";
import PlayerVolumeSlider from "../ui/PlayerVolumeSlider";
import PlayerPlayButton from "@/components/VideoLibrary/ui/PlayerPlayButton";

const MobileControlsContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2147483647200;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  pointer-events: auto;
`;

const ControlsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 12px;
`;

const SliderContainer = styled.div`
  flex: 1;
`;

interface MobilePlayerBottomControlsProps {
  isPlaying: boolean;
  onPlayClick?: () => void;
}

/**
 * Mobile-specific bottom controls for the video player
 * Contains position slider and volume controls
 * Must be used within MediaPlayer context tree
 */
export default function MobilePlayerBottomControls({ isPlaying, onPlayClick }: MobilePlayerBottomControlsProps) {
  // Handle play button click with event stopping
  const handlePlayClick = () => {
    if (onPlayClick) {
      onPlayClick();
    }
    return false;
  };

  return (
    <MobileControlsContainer>
      <ControlsRow>
        <PlayerPlayButton onClick={handlePlayClick} />
        <SliderContainer>
          <PlayerPositionSlider />
        </SliderContainer>
        <PlayerVolumeSlider />
      </ControlsRow>
    </MobileControlsContainer>
  );
}
