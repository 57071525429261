// components/VideoLibrary/ui/PlayerVolumeSlider.tsx
import { MuteButton, useMediaState } from "@vidstack/react";
import VideoVolumeFullIcon from "@/assets/svg/videoVolumeFull.svg";
import VideoVolumeMuteIcon from "@/assets/svg/videoVolumeMute.svg";
import styled from "styled-components";

const StyledMuteButton = styled(MuteButton)`
  cursor: pointer;
  width: 32px;
  height: 32px;
  color: #f7f8f8;
  svg {
    width: 16px;
    height: 16px;
  }
`;

/**
 * Volume control with mute button
 * Shows different icons based on muted state
 */
export default function PlayerVolumeSlider() {
  const isMuted = useMediaState("muted");
  return (
    <StyledMuteButton className='vds-button'>
      {isMuted ? <VideoVolumeMuteIcon /> : <VideoVolumeFullIcon />}
    </StyledMuteButton>
  );
}
