import React, { useRef, useEffect, useState, useCallback } from "react";
import styled, { css } from "styled-components";
import { motion, useTransform, useMotionValue, animate, MotionValue } from "motion/react";
import mq from "@/utils/mq";
import { Controls, Spinner } from "@vidstack/react";
import { useDrag } from "@use-gesture/react";
import useBreakpoint from "../../hooks/useBreakpoint";

const VideoLibraryPlayerContainer = styled(motion.div)<{ $isOpen: boolean }>`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(24px);
  z-index: 2147483647100;
  animation: fadeIn 0.18s;
  will-change: opacity;
  pointer-events: all;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  ${mq.mobile(css`
    padding-left: 0;
    padding-right: 0;
    align-items: center;
    animation: unset;
    touch-action: none;
  `)};

  ${({ $isOpen }) =>
    !$isOpen &&
    css`
      display: none;
      pointer-events: none;
      user-select: none;
      opacity: 0;
    `};
`;

const VideoPlayerContainer = styled(motion.div)`
  background: #121212;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  aspect-ratio: 16 / 9;
  max-width: 1200px;
  height: auto;
  border-radius: 8px;
  overflow: hidden;
  will-change: auto;
  animation: dialogOpen 0.18s;
  transform-origin: center;
  pointer-events: auto;

  @keyframes dialogOpen {
    0% {
      opacity: 0;
      transform: translate(-50%, -49%) scale(0.95);
    }
    100% {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  ${mq.mobile(css`
    position: relative;
    top: unset;
    left: unset;
    transform: unset;
    transform-origin: unset;
    border-radius: 0px;
    width: 100%;
    max-width: 100%;
    animation: dialogOpenMobile 0.4s;
    touch-action: pan-x;
    @keyframes dialogOpenMobile {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  `)};
`;

const GestureOverlay = styled.div`
  position: absolute;
  inset: 0;
  /* Important: Place below controls in z-index stack but above video */
  z-index: 2147483647105;
  /* Make the overlay transparent */
  background: transparent;
  touch-action: none;

  display: none;
  ${mq.mobile(css`
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
  `)};
`;

const VideoPlayerInnerContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const StyledMediaPlayer = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;

  ${mq.mobile(css`
    /* Style mobile controls when inside MediaPlayer */
    .mobile-header-controls {
      position: fixed !important;
      top: 0 !important;
      left: 0 !important;
      right: 0 !important;
      bottom: auto !important;
      height: auto !important;
      pointer-events: auto !important;
      z-index: 2147483647200 !important;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%) !important;
      padding: 16px !important;
      transition:
        opacity 0.3s ease,
        visibility 0.3s ease !important;
    }

    .mobile-bottom-controls {
      position: fixed !important;
      bottom: 0 !important;
      left: 0 !important;
      right: 0 !important;
      top: auto !important;
      height: auto !important;
      pointer-events: auto !important;
      z-index: 2147483647200 !important;
      padding: 0 !important; /* Remove default padding */
      transition:
        opacity 0.3s ease,
        visibility 0.3s ease !important;
    }

    /* Handle visible/hidden states */
    .visible {
      opacity: 1 !important;
      visibility: visible !important;
    }

    .hidden {
      opacity: 0 !important;
      visibility: hidden !important;
      pointer-events: none !important;
    }

    /* Hide all controls when swiping */
    [data-swipe-active="true"] & .vds-controls,
    [data-swipe-active="true"] & .mobile-header-controls,
    [data-swipe-active="true"] & .mobile-bottom-controls {
      opacity: 0 !important;
      visibility: hidden !important;
      pointer-events: none !important;
      transition:
        opacity 0.2s ease,
        visibility 0.2s ease !important;
    }
  `)};
`;

const PlayerControlsRoot = styled(Controls.Root)`
  z-index: 2147483647110;
  display: flex;
  pointer-events: auto;
  background: rgba(0, 0, 0, 0);
  opacity: 1 !important;
  visibility: visible !important;
  transition: background 0.3s ease-in-out;

  &[data-visible] {
    transition: background 0.3s ease-in-out;
    background: rgba(0, 0, 0, 0.3);
  }

  ${mq.mobile(css`
    /* Adjust layout for mobile to account for header being outside */
    padding-top: 0;
  `)};
`;

const SpinnerOuterContainer = styled.div`
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  z-index: 2147483647250;
  [data-buffering] & {
    opacity: 1;
  }
`;

interface VideoPlayerLayoutProps {
  isOpen: boolean;
  onBackgroundClick: (e: React.MouseEvent) => void;
  containerRef: any;
  children: React.ReactNode;
  onToggleControls?: () => void;
  animation?: any;
  transition?: any;
  onAnimationComplete?: (definition?: string) => void;
  initial?: any;
  exit?: any;
  variants?: any;
}

// Custom easing function that starts slow and accelerates
const easeOutCubic = (x: number): number => {
  return 1 - Math.pow(1 - x, 3);
};

// Custom hook for eased transform with easing function
const useEasedTransform = (
  value: MotionValue<number>,
  inputRange: number[],
  outputRange: number[],
  easingFn: (x: number) => number,
) => {
  return useTransform(value, (v) => {
    // Calculate progress through the input range (0 to 1)
    const progress = Math.max(0, Math.min(1, (v - inputRange[0]) / (inputRange[1] - inputRange[0])));

    // Apply easing to the progress
    const easedProgress = easingFn(progress);

    // Interpolate between output values using the eased progress
    return outputRange[0] + easedProgress * (outputRange[1] - outputRange[0]);
  });
};

/**
 * Layout component for the video player modal
 */
export default function VideoPlayerLayout({
  isOpen,
  onBackgroundClick,
  containerRef,
  children,
  onToggleControls,
  animation,
  transition,
  onAnimationComplete,
  initial,
  exit,
  variants,
}: VideoPlayerLayoutProps) {
  const { isMobileView } = useBreakpoint();
  const [isSwipeActive, setIsSwipeActive] = useState(false);

  // Motion values for the swipe gesture
  const y = useMotionValue(0);

  // Create eased transforms with custom easing curves
  const playerOpacity = useEasedTransform(y, [0, 300], [1, 0.1], easeOutCubic);
  const backdropOpacity = useEasedTransform(y, [0, 200], [1, 0], easeOutCubic);
  const scale = useTransform(y, [0, 300], [1, 0.85]);

  // Refs for gesture handling
  const motionRef = useRef<HTMLDivElement>(null);
  const gestureRef = useRef<HTMLDivElement>(null);

  // Sync with containerRef
  useEffect(() => {
    if (motionRef.current) {
      containerRef.current = motionRef.current;
    }
  }, [containerRef]);

  // Reset y position when modal opens or closes
  useEffect(() => {
    if (isOpen) {
      y.set(0);
      setIsSwipeActive(false);
    }
  }, [isOpen, y]);

  // Function to animate back to original position with spring physics
  const animateBackToPosition = () => {
    // Use spring animation for natural bounce-back feeling
    animate(y, 0, {
      type: "spring",
      stiffness: 300,
      damping: 20,
      velocity: y.getVelocity(),
      restDelta: 0.5,
      onComplete: () => {
        // Reset swipe state when animation completes
        setIsSwipeActive(false);
      },
    });
  };

  // Handle background click for dismissal
  const handleBackdropClick = (e: React.MouseEvent) => {
    // Only process if it's a direct click on the backdrop
    if (e.target === e.currentTarget) {
      onBackgroundClick(e);
    }
  };

  // Handle click on the player area - this will toggle controls
  const handlePlayerTap = (e: React.MouseEvent) => {
    // Don't process taps during active swipe on mobile
    if (isMobileView && isSwipeActive) return;

    // Check if the click is on iframe - don't process iframe clicks
    if (e.target instanceof HTMLElement) {
      const isIframe = e.target.tagName === "IFRAME" || !!e.target.closest("iframe");
      if (isIframe) return;
    }

    // Stop propagation so this doesn't trigger backdrop click
    e.stopPropagation();

    // Call the toggle controls function if provided
    if (onToggleControls) {
      onToggleControls();
    }
  };

  // When swipe becomes active, hide controls (mobile only)
  useEffect(() => {
    if (isMobileView && isSwipeActive && onToggleControls) {
      // Force hide controls on swipe start
      const event = new Event("hidecontrols");
      document.dispatchEvent(event);
    }
  }, [isSwipeActive, onToggleControls, isMobileView]);

  // Configure drag gesture handler - only enabled on mobile
  useDrag(
    ({ down, movement: [, my], velocity: [, vy], direction: [, dy], cancel, event, first }) => {
      try {
        // Safe check to prevent null pointer errors
        if (!event) return;

        // Don't process swipes on iframe elements
        const target = event.target as HTMLElement;
        if (!target) return;

        // Skip swipe processing on iframe elements
        if (target.tagName === "IFRAME" || !!target.closest("iframe")) {
          return;
        }

        // Set swipe active state on first movement
        if (first && my > 5) {
          setIsSwipeActive(true);
        }

        // Calculate the constrained movement with some resistance for upward movement
        // This creates a rubber-band effect when trying to swipe up beyond the origin
        const constrainedY = my < 0 ? my / 3 : my;

        if (down) {
          // Always update position to follow finger directly when dragging
          y.set(constrainedY);
        } else {
          // On release, check if we should dismiss or return to original position
          // Only dismiss if swiping downward (positive my and vy)
          if ((my > 250 && dy > 0) || (vy > 0.8 && dy > 0)) {
            // Threshold met and direction is downward, dismiss the player
            setIsSwipeActive(false);
            onBackgroundClick({} as any);
          } else {
            // Animate back to original position
            animateBackToPosition();
          }
        }
      } catch (error) {
        // If any error occurs, safely animate back to original position
        console.warn("Gesture error:", error);
        animateBackToPosition();
      }
    },
    {
      enabled: isMobileView && isOpen,
      filterTaps: true,
      threshold: 10, // Only start dragging after 10px movement
      from: () => [0, y.get()],
      axis: "y",
      target: gestureRef,
      // Capture touch events but still allow clicks
      pointer: { touch: true },
      // Use passive event option to avoid preventDefault issues
      eventOptions: { passive: true },
      // Use rubberband effect for more natural feel
      rubberband: true,
    },
  );

  return (
    <VideoLibraryPlayerContainer
      $isOpen={isOpen}
      onClick={handleBackdropClick}
      animate={animation}
      initial={initial}
      exit={exit}
      variants={variants}
      transition={transition}
      onAnimationComplete={onAnimationComplete}
      style={isMobileView ? { opacity: backdropOpacity } : undefined}
      data-swipe-active={isMobileView ? isSwipeActive : undefined}>
      {isMobileView ? (
        // Mobile layout with gesture support
        <VideoPlayerContainer
          ref={motionRef}
          onClick={handlePlayerTap}
          style={{ y, opacity: playerOpacity, scale }}
          data-swipe-active={isSwipeActive}>
          <VideoPlayerInnerContainer>
            <GestureOverlay ref={gestureRef} />
            {children}
          </VideoPlayerInnerContainer>
        </VideoPlayerContainer>
      ) : (
        // Desktop layout without any gesture-related styles
        <VideoPlayerContainer ref={motionRef} onClick={handlePlayerTap}>
          <VideoPlayerInnerContainer>{children}</VideoPlayerInnerContainer>
        </VideoPlayerContainer>
      )}
    </VideoLibraryPlayerContainer>
  );
}

// Export styled components for use in VideoPlayer
export { StyledMediaPlayer, PlayerControlsRoot, SpinnerOuterContainer, VideoPlayerInnerContainer };
