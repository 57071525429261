import React from "react";
import styled, { css } from "styled-components";
import { motion } from "motion/react";
import mq from "@/utils/mq";

import PlayerPlayButton from "./PlayerPlayButton";
import PlayerPositionSlider from "./PlayerPositionSlider";
import PlayerVolumeSlider from "./PlayerVolumeSlider";
import PlayerFullScreenToggle from "./PlayerFullScreenToggle";

const PlayerControlsContainer = styled(motion.div)`
  position: absolute;
  pointer-events: auto;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2147483647110;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  gap: 34px;
  max-width: 638px;
  margin: 0 auto;
  transform: translateY(20px);
  transition:
    transform 0.3s ease-in-out,
    opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;

  ${mq.mobile(css`
    padding: 8px;
    gap: 16px;
    max-width: 100%;
  `)};

  .vds-controls[data-visible] & {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
`;

const PlayerControlsLeftGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const PlayerControlsRightGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-shrink: 0.1;
`;

interface VideoPlayerControlsProps {
  isPlaying: boolean;
  onPlayClick: () => void;
}

/**
 * Video player controls including play, volume, position, and fullscreen
 */
export default function VideoPlayerControls({ isPlaying, onPlayClick }: VideoPlayerControlsProps) {
  return (
    <PlayerControlsContainer onClick={(e) => e.stopPropagation()}>
      <PlayerControlsLeftGroup>
        <PlayerPlayButton onClick={onPlayClick} />
        <PlayerVolumeSlider />
      </PlayerControlsLeftGroup>

      <PlayerPositionSlider />

      <PlayerControlsRightGroup>
        <PlayerFullScreenToggle />
      </PlayerControlsRightGroup>
    </PlayerControlsContainer>
  );
}
